export enum Alignment {
  Start = 'Start',
  Center = 'Center',
  End = 'End'
}

export interface AlignmentsValue {
  horizontal: Alignment;
  vertical: Alignment;
}

export type AlignmentVariant = 'Horizontal' | 'Veritcal';

export class AlignmentFunctions {
  static getLabel(value: Alignment, variant: AlignmentVariant) {
    const isV = variant === 'Veritcal';
    switch (value) {
      case Alignment.Start:
        return isV ? 'Top' : 'Left';
      case Alignment.Center:
        return isV ? 'Middle' : 'Center';
      case Alignment.End:
        return isV ? 'Bottom' : 'Right';
      default:
        return value;
    }
  }

  static toTextCss(align: Alignment) {
    return align === Alignment.End
      ? 'right'
      : align === Alignment.Center
        ? 'center'
        : 'left';
  }

  static toFlexCss(align: Alignment) {
    return align === Alignment.End
      ? 'flex-end'
      : align === Alignment.Center
        ? 'center'
        : 'flex-start';
  }

  static toMarginAutoCss(align: Alignment) {
    return align === Alignment.End
      ? 'auto 0 0 0'
      : align === Alignment.Center
        ? 'auto 0'
        : '0';
  }
}
