import { FC } from 'react';

import { QrCodeGenerator } from '@/common/components/QrCode/QrCodeGenerator';

import { useBlockViewer, useBlockViewerItem } from '../../Viewer/Context';
import { BlockQrCodeItem } from './BlockQrCodeItem';

export const BlockQrCodeItemViewRenderer: FC = () => {
  const item = useBlockViewerItem((x) => x.item) as BlockQrCodeItem;
  const registerElement = useBlockViewerItem((x) => x.registerElement);
  const stageUtmMedium = useBlockViewer((x) => x.stageUtmMedium);
  const stageScale = useBlockViewer((x) => x.stageScale);
  const { qrOptions, containerStyle } = item.getRenderData(
    stageScale,
    stageUtmMedium
  );

  return (
    <QrCodeGenerator
      onInit={(_, container) => registerElement(container)}
      value={qrOptions}
      containerStyle={containerStyle}
    />
  );
};
