import { FC } from 'react';

import { Image } from '@/common/components/Image';
import { ImageDataModel } from '@/common/models/ImageDataModel';

import { useBlockViewer, useBlockViewerItem } from '../../Viewer/Context';
import { BlockImageItem } from './BlockImageItem';

interface Props {
  overrideImage?: ImageDataModel;
  overrideImageLoading?: boolean;
}
export const BlockImageItemViewRenderer: FC<Props> = ({
  overrideImage,
  overrideImageLoading
}) => {
  const item = useBlockViewerItem((x) => x.item) as BlockImageItem;
  const register = useBlockViewerItem((x) => x.registerElement);
  const stageScale = useBlockViewer((x) => x.stageScale);
  const { image, style } = item.getRenderData(stageScale);

  if (overrideImageLoading) return null;

  const renderImage = overrideImage ?? image;

  return (
    <Image
      onLoad={(e) => register(e.currentTarget)}
      image={renderImage}
      style={style}
    />
  );
};
