import { FC } from 'react';

import { Image } from '@/common/components/Image';
import { VideoPlayer } from '@/common/components/Video/Player';
import { deepCompareMemo } from '@/common/utils/deepCompareMemo';

import { BlockBackground } from '../types/BlockBackground';

interface Props {
  background: BlockBackground;
  fallbackColor?: string;
}

export const BlockBackgroundRenderer: FC<Props> = deepCompareMemo(
  ({ background, fallbackColor }) => {
    const {
      image,
      hasImage,
      videoUrl,
      hasVideoUrl,
      hasOverlay,
      containerStyle,
      imageStyle,
      overlayStyle
    } = background.getRenderData(fallbackColor);

    return (
      <>
        <div style={containerStyle}>
          {hasVideoUrl && (
            <VideoPlayer
              url={videoUrl}
              layout="inline"
              hideControls
              playing
              playsinline
              muted
              loop
            />
          )}
          {!hasVideoUrl && hasImage && (
            <Image image={image} style={imageStyle} />
          )}
        </div>
        {hasOverlay && <div style={overlayStyle} />}
      </>
    );
  }
);
