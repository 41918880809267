import { FCC } from 'fcc';
import { MutableRefObject, useCallback } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import { useConstant } from '@/common/hooks/useConstant';
import { useDeepCompareMemo } from '@/common/hooks/useDeepCompareEffects';
import { Guid } from '@/common/models/Guid';

import { BlockDataSource } from './BlockDataSource';
import { DataSourceInstance } from './DataSourceInstance';
import {
  DataSourceResolver,
  DataSourceResolverFunc,
  DataSourceResolverProps
} from './DataSourceResolver';

interface DataSourceContextProps {
  dataSourcesRef: MutableRefObject<DataSourceInstance[]>;
}

export const DataSourceContext =
  createContext<DataSourceContextProps>(undefined);

interface DataSourceProviderProps {
  dataSourcesRef: MutableRefObject<DataSourceInstance[]>;
}

export const DataSourceProvider: FCC<DataSourceProviderProps> = ({
  dataSourcesRef,
  children
}) => {
  const value = useConstant<DataSourceContextProps>(() => ({
    dataSourcesRef
  }));

  return (
    <DataSourceContext.Provider value={value}>
      {children}
    </DataSourceContext.Provider>
  );
};

// - Resolver context

interface DataSourceResolverContextProps {
  dataSourceResolver: DataSourceResolverFunc;
}

export const DataSourceResolverContext =
  createContext<DataSourceResolverContextProps>(undefined);

interface DataSourceResolverProviderProps {
  dataSourceResolver: DataSourceResolver;
}

export const DataSourceResolverProvider: FCC<
  DataSourceResolverProviderProps
> = ({ dataSourceResolver, children }) => {
  // We use a callback here to avoid some issues with closures if you just pass resolver.resolve
  const dataSourceResolverFunc = useCallback(
    (props: DataSourceResolverProps) => dataSourceResolver.resolve(props),
    [dataSourceResolver]
  );

  const value = useDeepCompareMemo<DataSourceResolverContextProps>(
    () => ({
      dataSourceResolver: dataSourceResolverFunc
    }),
    [dataSourceResolverFunc]
  );

  return (
    <DataSourceResolverContext.Provider value={value}>
      {children}
    </DataSourceResolverContext.Provider>
  );
};

// - hooks

function useDataSourceContext<T>(
  selector: (value: DataSourceContextProps) => T
): T {
  return useContextSelector(DataSourceContext, (state) => {
    if (state === undefined) {
      throw new Error(
        'useDataSourceContext must be used within a DataSourceContext'
      );
    }

    return selector(state);
  });
}

function useDataSourceResolverContext<T>(
  selector: (value: DataSourceResolverContextProps) => T
): T {
  return useContextSelector(DataSourceResolverContext, (state) => {
    if (state === undefined) {
      throw new Error(
        'useDataSourceResolverContext must be used within a DataSourceResolverContext'
      );
    }

    return selector(state);
  });
}

export const useRegisterBlockDataSource = () => {
  const dataSourcesRef = useDataSourceContext((x) => x.dataSourcesRef);
  const dataSourceResolver = useDataSourceResolverContext(
    (x) => x.dataSourceResolver
  );

  const registerBlockDataSource = (
    blockDataSource: BlockDataSource
  ): DataSourceInstance => {
    const found = dataSourcesRef.current.find((x) =>
      x.blockDataSource.isEqual(blockDataSource)
    );

    if (found) {
      return found;
    }

    // new up
    const instance = dataSourceResolver({ blockDataSource });
    if (instance) {
      dataSourcesRef.current.push(instance);
      return instance;
    } else {
      console.error(
        'Unable to resolve data source instance for data source',
        blockDataSource
      );

      return undefined;
    }
  };

  return registerBlockDataSource;
};

export const useDataSourceInstance = (id: Guid) => {
  const dataSourcesRef = useDataSourceContext((x) => x.dataSourcesRef);

  return dataSourcesRef.current.find((x) => x.id.equals(id));
};

export const useDataSourceInstances = () => {
  const dataSourcesRef = useDataSourceContext((x) => x.dataSourcesRef);

  return dataSourcesRef.current;
};

export const useGetDataSourceInstance = () => {
  const dataSourcesRef = useDataSourceContext((x) => x.dataSourcesRef);

  return (id: Guid) => dataSourcesRef.current.find((x) => x.id.equals(id));
};

export const useGetDataSourceInstances = () => {
  const dataSourcesRef = useDataSourceContext((x) => x.dataSourcesRef);

  return (ids: Guid[] = []) =>
    dataSourcesRef.current.filter((x) => ids.some((i) => x.id.equals(i)));
};
