import { Alignment } from '@/common/models/Alignment';
import { HeightWidth } from '@/common/models/HeightWidth';

import {
  BlockBackground,
  BlockItemInheritData,
  BlockMarginPadding,
  BlockMeasurementUnit,
  BlockStackVariant
} from './_shared';
import { BlockRenderSize, BlockRenderSizeDimension } from './BlockRenderSize';

export class BlockStageModelData implements BlockItemInheritData {
  size: BlockStageSize;
  childStack: BlockStackVariant;
  background: BlockBackground;
  padding: BlockMarginPadding;
  hAlign: Alignment;
  vAlign: Alignment;
  color?: string = '#000000';
  fontSize?: number = 16;
  fontFamily: string = "'Open Sans', sans-serif";

  get inheritData(): BlockItemInheritData {
    return {
      color: this.color,
      fontSize: this.fontSize,
      fontFamily: this.fontFamily
    };
  }
  constructor(props?: Partial<BlockStageModelData>) {
    props = props || {};
    Object.assign(this, props);
    this.size = new BlockStageSize(props.size);
    this.padding = new BlockMarginPadding(props.padding);
    this.background = new BlockBackground(props.background);
    if (!this.childStack) this.childStack = 'Vertical';
    if (!this.hAlign) this.hAlign = Alignment.Start;
    if (!this.vAlign) this.vAlign = Alignment.Start;
  }

  resolveScale(elementWidth: number): number {
    return this.size.resolveScale(elementWidth);
  }

  getRenderSize(): BlockRenderSize {
    return this.size.getRenderSize();
  }
}

export class BlockStageSize {
  maxWidth: number;
  maxHeight?: number;

  get hasMaxHeight() {
    return !!this.maxHeight;
  }

  get hasLockedSize() {
    return !!this.maxWidth && this.hasMaxHeight;
  }

  constructor(props?: Partial<BlockStageSize>) {
    props = props || {};
    Object.assign(this, props);
  }

  tryGetAspectRatio(): HeightWidth | undefined {
    return this.hasLockedSize
      ? {
          height: this.maxHeight,
          width: this.maxWidth
        }
      : undefined;
  }

  resolveScale(elementWidth: number): number {
    if (!elementWidth) return 1;
    if (!this.maxHeight) return 1;
    return elementWidth / this.maxWidth;
  }

  getRenderSize() {
    const renderSize = new BlockRenderSize({
      width: new BlockRenderSizeDimension({
        allowedMax: this.maxWidth,
        max: BlockMeasurementUnit.fromPixelValue(this.maxWidth),
        value: BlockMeasurementUnit.fromPixelValue(this.maxWidth)
      })
    });

    if (this.hasMaxHeight) {
      renderSize.height.allowedMax = this.maxHeight;
      renderSize.height.max = BlockMeasurementUnit.fromPixelValue(
        this.maxHeight
      );
      renderSize.height.value = BlockMeasurementUnit.fromPixelValue(
        this.maxHeight
      );
    }

    return renderSize;
  }
}
