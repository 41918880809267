import { FC, memo } from 'react';

import { ErrorBoundary } from '@/common/components/ErrorBoundary';

import { BlockButtonItemViewRenderer as ButtonRenderer } from '../Items/Button';
import { BlockCustomItemViewRenderer as CustomRenderer } from '../Items/Custom';
import { BlockImageItemViewRenderer as ImageRenderer } from '../Items/Image';
import { BlockLayoutItemViewRenderer as LayoutRenderer } from '../Items/Layout';
import { BlockQrCodeItemViewRenderer as QrCodeRenderer } from '../Items/QrCode';
import { BlockTextItemViewRenderer as TextRenderer } from '../Items/Text';
import { BlockTypes } from '../types/BlockTypes';
import { IHasBlockItemId } from '../types/IHasBlockItemId';
import { useBlockViewer } from './Context';
import { BlockViewerItemProvider } from './Context/BlockViewerItemProvider';

export const BlockViewerItemRenderer: FC<IHasBlockItemId> = memo(
  ({ itemId }) => {
    const item = useBlockViewer((x) => x.stage.items[itemId]);

    const { type } = item;

    const renderItem = () => {
      switch (type) {
        case BlockTypes.Button:
          return <ButtonRenderer />;
        case BlockTypes.Layout:
          return <LayoutRenderer />;
        case BlockTypes.Text:
          return <TextRenderer />;
        case BlockTypes.Image:
          return <ImageRenderer />;
        case BlockTypes.QrCode:
          return <QrCodeRenderer />;
        case BlockTypes.Custom:
          return <CustomRenderer />;
        default:
          return <>Not yet implemented</>;
      }
    };

    return (
      <ErrorBoundary fallback={<></>}>
        <BlockViewerItemProvider item={item}>
          {renderItem()}
        </BlockViewerItemProvider>
      </ErrorBoundary>
    );
  }
);
