import { FC, memo } from 'react';

import { useHasMountedState } from '@/common/hooks/useHasMounted';

import { BlockViewerItemRenderer } from '../Viewer/BlockViewerItemRenderer';
import { useBlockViewerItem } from '../Viewer/Context/BlockViewerItemProvider';

export const BlockItemChildrenViewRenderer: FC = memo(() => {
  const hasMounted = useHasMountedState();
  const childIds = useBlockViewerItem((x) => x.item.childIds);
  if (!hasMounted || !childIds?.length) {
    return null;
  }

  return (
    <>
      {childIds.map((id) => (
        <BlockViewerItemRenderer key={id} itemId={id} />
      ))}
    </>
  );
});
