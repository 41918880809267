import { FCC } from 'fcc';
import { nanoid } from 'nanoid';
import { useCallback, useMemo, useRef, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import { Dictionary } from '@/common/models/Dictionary';
import { HeightWidth } from '@/common/models/HeightWidth';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { mapArray, tryAddToArray } from '@/common/utils/ArrayFunctions';
import { PreloadImageData } from '@/common/utils/ImageFunctions';

import { Box } from '../../Display';
import { ErrorBoundary } from '../../ErrorBoundary';
import { ImageLoader } from './ImageLoader';

export class ImagePreloadRequest {
  displaySize?: HeightWidth;
  image: ImageDataModel;
  key: string;
  constructor(props?: Partial<ImagePreloadRequest>) {
    props = props || {};
    Object.assign(this, props);
    this.image = new ImageDataModel(props.image);

    this.key = this.displaySize
      ? `${this.image.url}::${this.displaySize.width}w::${this.displaySize.height}h`
      : this.image.url;
  }
}

export interface ProcessPreloadImageBatchOptions {
  images: ImagePreloadRequest[];
  debug?: boolean;
  onProcess?: (image: PreloadImageData) => void;
  onFinish?: (data: PreloadImageData[]) => void;
}

class BatchJob {
  id: string;
  debug?: boolean;

  private onProcess?: (image: PreloadImageData) => void;
  private onFinish?: (data: PreloadImageData[]) => void;
  private images: Dictionary<{
    preloadRequest: ImagePreloadRequest;
    data?: PreloadImageData;
  }> = {};

  constructor(options: ProcessPreloadImageBatchOptions) {
    this.id = nanoid();
    (options?.images || [])
      .filter((x) => !!x?.image?.url)
      .forEach((preloadRequest) => {
        if (!this.hasImagePreloadRequest(preloadRequest.key)) {
          this.images[preloadRequest.key] = {
            preloadRequest
          };
        }
      }, this);
    this.onProcess = options?.onProcess;
    this.onFinish = options?.onFinish;
    this.debug = options?.debug;
    if (this.debug) {
      this.debugLog(`Image preloader job ${this.id}: Created`);
    }
  }

  getPreloadRequests() {
    return Object.keys(this.images).map(
      (key) => this.images[key].preloadRequest,
      this
    );
  }

  getImagesData() {
    return Object.keys(this.images)
      .map((key) => this.images[key].data, this)
      .filter((x) => !!x);
  }

  process(key: string, data: PreloadImageData) {
    if (!this.hasImagePreloadRequest(key)) {
      return;
    }
    this.images[key].data = data;
    if (this.debug) {
      this.debugLog(`Image preloader job ${this.id}: Processed ${key}`, data);
    }
    this.onProcess?.(data);
  }

  tryFinish() {
    if (!this.isDone()) {
      return;
    }

    if (this.debug) {
      this.debugLog(`Image preloader job ${this.id}: Finished`);
    }
    this.onFinish?.(this.getImagesData());
  }

  isDone() {
    return Object.keys(this.images).every((key) => {
      return !!this.images[key].data;
    }, this);
  }

  hasImagePreloadRequest(key: string) {
    return !!this.images[key];
  }

  private debugLog(message: any, data?: any) {
    console.debug(message, data);
  }
}

interface ContextProps {
  processBatch: (options: ProcessPreloadImageBatchOptions) => void;
}

export const ImagePreloaderContext = createContext<ContextProps>(null);

export const ImagePreloaderProvider: FCC = ({ children }) => {
  const batchJobRef = useRef<Dictionary<BatchJob>>({});
  const completedRef = useRef<Dictionary<PreloadImageData>>({});
  const [processing, setProcessing] = useState<ImagePreloadRequest[]>([]);

  const handleProcessBatch = useCallback(
    (options: ProcessPreloadImageBatchOptions) => {
      if (!options?.images?.length) {
        options?.onFinish?.([]);
        return;
      }

      const job = new BatchJob(options);
      batchJobRef.current[job.id] = job;
      const imagesToProcess: ImagePreloadRequest[] = [];
      job.getPreloadRequests().forEach((preloadRequest) => {
        const completed = completedRef.current[preloadRequest.key];
        if (completed) {
          job.process(preloadRequest.key, completed);
        } else {
          imagesToProcess.push(preloadRequest);
        }
      });

      if (!imagesToProcess.length) {
        job.tryFinish();
        return;
      }

      setProcessing((old) => {
        const newImages = mapArray(old, (x) => new ImagePreloadRequest(x));
        imagesToProcess.forEach((i) => {
          tryAddToArray(newImages, i, (x) => x.key === i.key);
        });
        return newImages;
      });
    },
    []
  );

  const handleProcessItem = useCallback(
    (key: string, data: PreloadImageData) => {
      completedRef.current[key] = data;
      setProcessing((x) => x.filter((x) => x.key !== key));
      Object.keys(batchJobRef.current).forEach((id) => {
        const job = batchJobRef.current[id];
        if (job?.hasImagePreloadRequest?.(key)) {
          job.process(key, data);
          job.tryFinish();
          if (job.isDone()) {
            delete batchJobRef.current[job.id];
          }
        }
      });
    },
    []
  );
  const value = useMemo<ContextProps>(
    () => ({
      processBatch: handleProcessBatch
    }),
    [handleProcessBatch]
  );

  return (
    <ImagePreloaderContext.Provider value={value}>
      <ErrorBoundary
        fallback={
          <Box w={400} h={400} top={-99999} left={-99999} pos={'fixed'} />
        }
      >
        <Box w={400} h={400} top={-99999} left={-99999} pos={'fixed'}>
          {processing.map((x) => (
            <ErrorBoundary
              key={x.key}
              fallback={<div />}
              onError={() =>
                handleProcessItem(x.key, {
                  image: null,
                  success: false,
                  url: x.image.url
                })
              }
            >
              <ImageLoader
                key={x.key}
                image={x.image}
                displaySize={x.displaySize}
                onFinish={(d) => handleProcessItem(x.key, d)}
              />
            </ErrorBoundary>
          ))}
        </Box>
      </ErrorBoundary>

      {children}
    </ImagePreloaderContext.Provider>
  );
};

function useImagePreloader<T>(selector: (value: ContextProps) => T): T {
  return useContextSelector(ImagePreloaderContext, (state) => {
    if (!state) {
      throw new Error(
        'useImagePreloader must be used within a ImagePreloaderContext'
      );
    }
    return selector(state);
  });
}

export const useSizedImagePreloaderProcessBatch = () => {
  return useImagePreloader((x) => x.processBatch);
};

export interface UnsizedProcessPreloadImageBatchOptions {
  images: ImageDataModel[];
  debug?: boolean;
  onProcess?: (image: PreloadImageData) => void;
  onFinish?: (data: PreloadImageData[]) => void;
}

export const useUnsizedImagePreloaderProcessBatch = () => {
  const preloadImages = useImagePreloader((x) => x.processBatch);

  return useCallback(
    (options: UnsizedProcessPreloadImageBatchOptions) => {
      preloadImages({
        ...options,
        images: options.images.map((x) => new ImagePreloadRequest({ image: x }))
      });
    },
    [preloadImages]
  );
};
