import { Dictionary } from '@/common/models/Dictionary';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { isNil } from '@/common/utils/TypeFunctions';

import { BlockItem } from '../BlockItem';
import {
  BlockItemInheritData,
  BlockItemInheritSourceData,
  mapInheritDataWithSource
} from '../BlockItemData';
import { BlockTypes } from '../BlockTypes';

export class BlockItemTreeNode {
  id: string;
  type: BlockTypes;
  customType?: string;
  inheritData: BlockItemInheritSourceData;
  parentId?: string;
  children: BlockItemTreeNode[];

  constructor(props?: Partial<BlockItemTreeNode>) {
    props = props || {};
    Object.assign(this, props);
    this.children = mapArray(props.children, (x) => new BlockItemTreeNode(x));
  }

  isSelfOrChild(id: string) {
    if (this.id === id) return true;
    return this.children.some((x) => x.isSelfOrChild(id));
  }

  hasChild(id: string) {
    return this.children.some((x) => x.isSelfOrChild(id));
  }

  flatten(): BlockItemTreeNode[] {
    return [
      this,
      ...this.children.reduce((all, current) => {
        all.push(...current.flatten());
        return all;
      }, [])
    ];
  }

  static dictionaryFromStage(options: {
    allItems: Dictionary<BlockItem>;
    stageChildIds: string[];
    stageInheritData: BlockItemInheritData;
  }) {
    const model: Dictionary<BlockItemTreeNode> = {};
    const { allItems, stageChildIds, stageInheritData } = options;
    stageChildIds.forEach((itemId) => {
      const item = BlockItemTreeNode.fromItem({
        allItems,
        itemId,
        parent: {
          id: null,
          inheritData: mapInheritDataWithSource(stageInheritData, 'stage')
        }
      });
      item.flatten().forEach((x) => {
        model[x.id] = x;
      });
    }, this);

    return model;
  }

  static fromItem(options: {
    allItems: Dictionary<BlockItem>;
    itemId: string;
    parent: {
      id: string | null;
      inheritData: BlockItemInheritSourceData;
    };
  }): BlockItemTreeNode {
    const { allItems, itemId, parent } = options;
    const { type, customType, childIds = [] } = allItems[itemId];
    const { inheritData } = allItems[itemId].getData();

    return new BlockItemTreeNode({
      id: itemId,
      type,
      customType,
      parentId: parent.id,
      inheritData: parent.inheritData,
      children: childIds.map((childId) =>
        BlockItemTreeNode.fromItem({
          allItems,
          itemId: childId,
          parent: {
            id: itemId,
            inheritData: mergeInheritData({
              fromParent: parent.inheritData,
              itemId: itemId,
              itemData: inheritData
            })
          }
        })
      )
    });
  }
}

const mergeInheritData = (options: {
  fromParent: BlockItemInheritSourceData;
  itemId: string;
  itemData: BlockItemInheritData;
}) => {
  const { fromParent, itemData, itemId } = options;
  const merged = { ...fromParent };

  Object.keys(itemData).forEach((key) => {
    const itemValue = itemData[key];
    if (!isNil(itemValue)) {
      merged[key] = { source: itemId, value: itemValue };
    }
  });

  return merged;
};
