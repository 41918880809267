import { FCC } from 'fcc';

import { Center } from '@/common/components/Display/Center';
import { Spinner, SpinnerProps } from '@/common/components/Spinner';

export const Loader: FCC<SpinnerProps> = ({ children, ...rest }) => (
  <Center h="100%" w="100%">
    <Spinner {...rest} />
    {children}
  </Center>
);
