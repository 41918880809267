import { nanoid } from 'nanoid';
import { CSSProperties } from 'react';
import isEqual from 'react-fast-compare';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { AlignmentFunctions } from '@/common/models/Alignment';
import { HeightWidth } from '@/common/models/HeightWidth';
import {
  ImageDataModel,
  ImagesPromiseFunc
} from '@/common/models/ImageDataModel';
import { OperationResult } from '@/common/models/OperationResult';
import { MutateAction } from '@/common/models/Updates';
import { createFromRange, mapArray } from '@/common/utils/ArrayFunctions';
import { Fraction } from '@/common/utils/Fraction';
import {
  filterKeys,
  filterPrivateProperties
} from '@/common/utils/ObjectFunctions';
import { isNil, nameOf } from '@/common/utils/TypeFunctions';

import {
  BlockItemAspectRatioData,
  BlockItemData,
  BlockItemSizeAutoBehaviour,
  BlockRenderAutoBehaviour,
  BlockRenderType,
  BlockSize,
  BlockTypes,
  IBlockItem
} from './_shared';
import { BlockEditorElementProps } from './BlockEditorElementProps';
import {
  BlockItemChildOptions,
  BlockItemSessionData,
  BlockItemSizeOptions
} from './BlockItemSessionData';
import {
  BlockRenderSize,
  BlockRenderSizeItemResolveOptions,
  BlockRenderSizeParentDto
} from './BlockRenderSize';

export type ItemResolverFunc = (item: IBlockItem) => BlockItem;

export interface IHasBlockItem<TItem extends BlockItem = BlockItem> {
  item: TItem;
}

export interface BlockItemDimensionEditData {
  autoBehaviour: BlockRenderAutoBehaviour;
  autoBehaviourLocked: boolean;
  canSetFixed: boolean;
}
export interface BlockItemSizeEditData {
  height: BlockItemDimensionEditData;
  width: BlockItemDimensionEditData;
  aspectRatio: BlockItemAspectRatioData;
}

export interface BaseBlockItemConstructorProps<
  TData extends BlockItemData = BlockItemData
> {
  childOptions: BlockItemChildOptions;
  sizeOptions: BlockItemSizeOptions<TData>;
}
export abstract class BlockItem<TData extends BlockItemData = BlockItemData>
  implements IBlockItem
{
  private sessionData: BlockItemSessionData;

  renderSize: BlockRenderSize;
  id: string;
  name: string;
  jsonData: string;

  childIds: string[] = [];
  /**
   * @deprecated Should use childIds
   */
  children: BlockItem[];

  removeDirectChildId(id: string) {
    this.childIds = [...this.childIds.filter((x) => x !== id)];
  }

  get changeKeys() {
    return this.sessionData.changeKeys;
  }

  get childOptions() {
    return this.sessionData.childOptions;
  }

  get childCount() {
    return this.childIds?.length || 0;
  }

  get hasChildren() {
    return !!this.childCount;
  }

  getDisplayName() {
    const name = this.name || this.getTypeName();
    if (!this.childOptions.enabled || this.type === BlockTypes.Button) {
      return name;
    }

    const isV = this.getData().childStack === 'Vertical';
    return `${name} ${isV ? '↕️' : '↔️'}`;
  }

  abstract type: BlockTypes;
  customType?: string;
  abstract resolveImageAssetsFuncAsync(): ImagesPromiseFunc;

  constructor({
    childOptions,
    sizeOptions
  }: BaseBlockItemConstructorProps<TData>) {
    if (!this.sessionData) {
      this.sessionData = BlockItemSessionData.new<TData>(
        childOptions,
        sizeOptions
      );
    }
  }

  abstract getData(): TData;

  protected abstract resolveUpdateData(change: Partial<TData>): TData;

  updateData(change: Partial<TData>) {
    this.jsonData = JSON.stringify(this.resolveUpdateData(change));
    this.mutateSessionData((x) => ++x.changeKeys.data);
  }

  mutateData(action: (data: TData) => void): void {
    const data = this.getData();
    action(data);
    this.jsonData = JSON.stringify(data);
  }

  mutateSizeData(action: (size: BlockSize) => void) {
    const data = this.getData();
    action(data.size);
    const update: any = {
      [nameOf<BlockItemData>('size')]: new BlockSize(data.size)
    };

    this.updateData(update);
  }

  setParentId(id: string | undefined) {
    this.mutateSessionData((x) => (x.parentId = id));
  }

  getParentId() {
    return this.sessionData.parentId;
  }

  setCanDelete(value: boolean) {
    this.mutateSessionData((x) => (x.canDelete = value));
  }

  canDelete() {
    return this.sessionData.canDelete;
  }

  setCanClone(value: boolean) {
    this.mutateSessionData((x) => (x.canClone = value));
  }

  canClone() {
    return this.sessionData.canClone;
  }

  getTypeName(): string {
    switch (this.type) {
      case BlockTypes.Custom:
        return this.customType;
      case BlockTypes.QrCode:
        return 'QR Code';
      default:
        return this.type;
    }
  }

  getChildStack() {
    return this.getData().childStack;
  }

  getElementProps(type: BlockRenderType): BlockEditorElementProps {
    return BlockEditorElementProps.fromItem(this.id, this.type, type);
  }

  sanitiseRenderSize({
    parent,
    siblingsAndSelf
  }: Omit<BlockRenderSizeItemResolveOptions, 'itemId'>) {
    const beforeSize = new BlockRenderSize(this.renderSize);
    this.renderSize = BlockRenderSize.resolveForItem({
      itemId: this.id,
      parent,
      siblingsAndSelf
    });

    if (!isEqual(beforeSize, this.renderSize)) {
      this.mutateSessionData((x) => ++x.changeKeys.renderSize);
    }
  }

  getBaseStyle(scale: number): CSSProperties {
    const { padding, childStack, hAlign, vAlign } = this.getData();
    const style: CSSProperties = {
      position: 'relative',
      ...this.getInheritStyle(scale),
      ...this.renderSize.getStyle({
        scale,
        autoBehaviour: this.getAutoBehaviour()
      }),
      padding: padding.getCssValue(scale)
    };

    if (!isNil(hAlign)) {
      style.justifyContent = AlignmentFunctions.toFlexCss(hAlign);
      style.justifyItems = AlignmentFunctions.toFlexCss(hAlign);
    }

    if (!isNil(vAlign)) {
      style.alignContent = AlignmentFunctions.toFlexCss(vAlign);
      style.alignItems = AlignmentFunctions.toFlexCss(vAlign);
    }

    if (this.childOptions.enabled) {
      style.display = 'grid';
      if (this.hasChildren) {
        if (childStack === 'Horizontal') {
          style.gridTemplateColumns = BlockItem.resolveGridTemplateValues(
            this.childCount
          );
        }

        if (childStack === 'Vertical') {
          style.gridTemplateRows = BlockItem.resolveGridTemplateValues(
            this.childCount
          );
        }
      }
    }

    return style;
  }

  getInheritStyle(scale: number): CSSProperties {
    const { color, fontFamily, fontSize } = this.getData().inheritData;
    return {
      color: color || 'inherit',
      fontFamily: fontFamily || 'inherit',
      fontSize: !isNil(fontSize) ? fontSize * scale : 'inherit'
    };
  }

  canAcceptItemAsChild(item: IBlockItem): OperationResult {
    if (!item) return OperationResult.error('No item provided');
    const { type } = item;
    if (this.childOptions?.allowedTypes?.length) {
      if (!this.childOptions.allowedTypes.some((x) => x === type)) {
        return OperationResult.error(
          `${item.type} item: ${item.id} not in specified allowed types`,
          { data: item.id }
        );
      }
    }

    if (this.childOptions?.notAllowedTypes?.length) {
      if (this.childOptions.notAllowedTypes.some((x) => x === type)) {
        return OperationResult.error(
          `${item.type} item: ${item.id} in specified not allowed types`,
          { data: item.id }
        );
      }
    }

    return OperationResult.success();
  }

  getSizeEditData(): BlockItemSizeEditData {
    const options = this.sessionData.sizeOptions;

    const autoValues = this.getAutoBehaviour();
    return {
      aspectRatio: this.getAspectRatioData(),
      height: {
        autoBehaviour: autoValues.height,
        autoBehaviourLocked: !isNil(options.height.lockedAutoBehaviour),
        canSetFixed: !options.height.fixedDisabled
      },
      width: {
        autoBehaviour: autoValues.width,
        autoBehaviourLocked: !isNil(options.width.lockedAutoBehaviour),
        canSetFixed: !options.width.fixedDisabled
      }
    };
  }

  getAutoBehaviour(): BlockItemSizeAutoBehaviour {
    const {
      size: { heightAutoBehaviour, widthAutoBehaviour }
    } = this.getData();
    const options = this.sessionData.sizeOptions;

    return {
      height:
        options.height.lockedAutoBehaviour || heightAutoBehaviour || 'Hug',
      width: options.width.lockedAutoBehaviour || widthAutoBehaviour || 'Hug'
    };
  }

  getAspectRatioData(): BlockItemAspectRatioData {
    const data = this.getData();

    const options = this.sessionData.sizeOptions.aspectRatio;
    let value: HeightWidth = undefined;
    let lockedValue: HeightWidth = undefined;
    let disabled: boolean = false;

    if (options.enabled) {
      disabled = true;
      value = data.size.aspectRatio;
      if (options.locked) {
        lockedValue = options.locked.value(data);
        disabled = options.locked.disabled;
        if (!!value || disabled) {
          value = lockedValue;
        }
      }
    }
    const hasValue = !!value;
    const fraction = hasValue
      ? new Fraction(value.width, value.height)
      : undefined;

    return {
      enabled: options.enabled,
      value,
      lockedValue,
      display: hasValue
        ? `${fraction.numerator}W/${fraction.denominator}H`
        : '-',
      hasValue,
      canToggle: hasValue && disabled
    };
  }

  getBlockRenderSizeParentDto(): BlockRenderSizeParentDto {
    const { padding, childStack } = this.getData();
    return {
      padding,
      size: this.renderSize,
      stack: childStack
    };
  }

  protected setDefaults() {
    if (!this.id) this.id = nanoid();
    this.childIds = mapArray(this.childIds, (x) => x);

    if (this.renderSize) {
      this.renderSize = new BlockRenderSize(this.renderSize);
    }

    this.sanitiseSizeData();
  }

  //We want to enforce/cleanup item based on
  //aspect ratio options
  private sanitiseSizeData() {
    const ratioOptions = this.sessionData.sizeOptions.aspectRatio;
    this.mutateData((data) => {
      data.mutateSize((size) => {
        if (!ratioOptions.enabled && size.hasAspectRatio) {
          size.aspectRatio = null;
        }

        if (ratioOptions.enabled && ratioOptions.locked?.disabled) {
          size.aspectRatio = ratioOptions.locked.value(data);
        }
      });
    });
  }

  protected safeParseJsonData() {
    try {
      return JSON.parse(this.jsonData || '{}');
    } catch {
      return {};
    }
  }

  static resolveGridTemplateValues = (childrenCount: number) => {
    if (!childrenCount) {
      return undefined;
    }

    return createFromRange(1, childrenCount)
      .map(() => 'auto')
      .join(' ');
  };

  updateDataSource(dataSource: DataSourceInstance) {
    this.mutateData((x) => (x.dataSourceId = dataSource.id));
    this.mutateSessionData((x) => (x.dataSourceInstance = dataSource));
  }

  trySetDataSourceInstance(dataSources: DataSourceInstance[]) {
    const dataSourceId = this.getDataSourceId();
    if (dataSourceId) {
      this.sessionData.dataSourceInstance = dataSources.find((x) =>
        x.id.equals(dataSourceId)
      );
    }
  }

  getDataSourceId() {
    return this.getData().dataSourceId;
  }

  isCustom() {
    return this.type === BlockTypes.Custom && !!this.customType;
  }

  get dataSourceInstance() {
    return this.sessionData.dataSourceInstance;
  }

  get hasDataSourceInstance() {
    return !!this.sessionData.dataSourceInstance;
  }

  /**
   * Override which fields will be serialized - excludes session data
   */
  public toJSON() {
    return filterKeys(this, (key) => key !== 'sessionData');
  }

  //TODO REMOVE NEXT RELEASE AFTER 03/11/2022
  //WE ARE MOVING AWAY FROM PRIVATE PROPS STARTING WITH UNDERSCORES
  //ANY DATA THAT YOU DONT WANT PERSISTED PUT IN sessionData
  protected sanitiseProps(props: any) {
    const { _dataSource, ...rest } = props;
    return { _dataSource, ...filterPrivateProperties(rest) };
  }

  protected mutateSessionData(action: MutateAction<BlockItemSessionData>) {
    this.sessionData = new BlockItemSessionData(this.sessionData);
    action(this.sessionData);
  }

  preloadDataSourceAsync(): Promise<ImageDataModel[]> {
    return Promise.resolve([]);
  }
}

export interface DeleteBlockItemResult {
  success: boolean;
  parentId?: string;
  siblingId?: string;
}

export interface MutateBlockItemResult {
  success: boolean;
  shouldCheckRenderSize?: boolean;
  parentId?: string;
}
