import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';

import { BlockDataSource } from './BlockDataSource';

export interface IDataSourceInstance {
  id: Guid;
  blockDataSource: BlockDataSource;
  isEqual(other: DataSourceInstance): boolean;
  getImageAssetsAsync(): Promise<ImageDataModel[]>;
  isValid(): boolean;
}

export abstract class DataSourceInstance implements IDataSourceInstance {
  id: Guid;
  blockDataSource: BlockDataSource;

  protected applyDefaults() {
    this.blockDataSource = new BlockDataSource(this.blockDataSource);
    this.id = Guid.valueOrNew(this.id);
  }

  abstract isEqual(other: DataSourceInstance): boolean;

  abstract getImageAssetsAsync(): Promise<ImageDataModel[]>;

  abstract isValid(): boolean;
}
