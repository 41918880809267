import { isNil } from '@/common/utils/TypeFunctions';

export class BlockMarginPadding {
  left: number = 0;
  right: number = 0;
  top: number = 0;
  bottom: number = 0;

  get xSpacingEqual() {
    return this.left === this.right;
  }

  get ySpacingEqual() {
    return this.top === this.bottom;
  }

  getX(scale: number) {
    return this.left * scale + this.right * scale;
  }

  getY(scale: number) {
    return this.top * scale + this.bottom * scale;
  }

  getCssValue(scale: number) {
    return `${this.top * scale}px ${this.right * scale}px ${
      this.bottom * scale
    }px ${this.left * scale}px`;
  }

  constructor(props?: Partial<BlockMarginPadding>) {
    props = props || {};
    Object.assign(this, props);
    if (isNil(this.top)) this.top = 0;
    if (isNil(this.bottom)) this.bottom = 0;
    if (isNil(this.left)) this.left = 0;
    if (isNil(this.right)) this.right = 0;
  }

  static fromValue(value: number) {
    return new BlockMarginPadding({
      top: value,
      right: value,
      bottom: value,
      left: value
    });
  }

  static fromValues(verticalValue: number, horizontalValue: number) {
    return new BlockMarginPadding({
      top: verticalValue,
      right: horizontalValue,
      bottom: verticalValue,
      left: horizontalValue
    });
  }

  static empty() {
    return new BlockMarginPadding();
  }
}
