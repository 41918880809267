import { CSSProperties } from 'react';

import { Alignment } from '@/common/models/Alignment';
import {
  ImageDataModel,
  ImagesPromiseFunc
} from '@/common/models/ImageDataModel';
import { filterPrivateProperties } from '@/common/utils/ObjectFunctions';

import {
  BlockBackground,
  BlockItemData,
  BlockItemSizeAutoBehaviour,
  BlockTypes
} from '../../types/_shared';
import { BlockItem } from '../../types/BlockItem';

export class BlockLayoutItem extends BlockItem<BlockLayoutItemData> {
  type = BlockTypes.Layout;

  constructor(props?: Partial<BlockLayoutItem>) {
    props = props || {};
    super({
      sizeOptions: {
        aspectRatio: {
          enabled: false
        },
        height: {
          lockedAutoBehaviour: 'Fill'
        },
        width: {
          lockedAutoBehaviour: 'Fill'
        }
      },
      childOptions: {
        enabled: true
      }
    });
    Object.assign(this, filterPrivateProperties(props));
    this.setDefaults();
  }

  getData(): BlockLayoutItemData {
    return new BlockLayoutItemData(this.safeParseJsonData());
  }

  resolveUpdateData(change: Partial<BlockLayoutItemData>) {
    return new BlockLayoutItemData({
      ...this.getData(),
      ...change
    });
  }

  resolveImageAssetsFuncAsync(): ImagesPromiseFunc {
    const images: ImageDataModel[] = [];
    const data = this.getData();
    if (data.background?.backgroundImage?.url) {
      images.push(data.background.backgroundImage);
    }

    return () => Promise.resolve(images);
  }

  getRenderData(scale: number) {
    const data = this.getData();

    const style: CSSProperties = {
      ...this.getBaseStyle(scale),
      position: 'relative'
    };

    if (!this.hasChildren) {
      style.alignContent = 'center';
      style.justifyContent = undefined;
    }

    return {
      style,
      data
    };
  }

  static new(data?: Partial<BlockLayoutItemData>) {
    return new BlockLayoutItem({
      jsonData: JSON.stringify(
        new BlockLayoutItemData({
          ...(data || {})
        })
      )
    });
  }
}

export class BlockLayoutItemData extends BlockItemData {
  background: BlockBackground;
  constructor(props?: Partial<BlockLayoutItemData>) {
    super();
    Object.assign(
      this,
      this.mergeDefaults({
        ...BlockLayoutItemData.defaults,
        ...(props || {})
      })
    );
    this.setDefaults();
  }

  static defaults: Partial<BlockLayoutItemData> = {
    childStack: 'Vertical',
    hAlign: Alignment.Start,
    vAlign: Alignment.Start
  };

  protected setDefaults() {
    super.setDefaults();
    this.background = new BlockBackground(this.background);
  }

  defaultAutoBehaviour(): BlockItemSizeAutoBehaviour {
    return {
      height: 'Fill',
      width: 'Fill'
    };
  }
}
